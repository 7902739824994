window.addEventListener('load', function(){
    new Glider(document.querySelector('.glider'), {
        slidesToScroll: 1,
        slidesToShow: 1.1,
        draggable: true,
        scrollLock: false,
        scrollLockDelay: 0,
        duration: 0.2,
        rewind: true,
        dots: '.dots'
    });
});

// const lightbox = new PhotoSwipeLightbox({
//     gallery: '.gallery',
//     children: 'a',
//     pswpModule: PhotoSwipe
// });



// lightbox.init();